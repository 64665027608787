/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'shield-fill-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 00-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.8 11.8 0 002.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7 7 0 001.048-.625 11.8 11.8 0 002.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.54 1.54 0 00-1.044-1.263 63 63 0 00-2.887-.87C9.843.266 8.69 0 8 0M6.854 5.146L8 6.293l1.146-1.147a.5.5 0 11.708.708L8.707 7l1.147 1.146a.5.5 0 01-.708.708L8 7.707 6.854 8.854a.5.5 0 11-.708-.708L7.293 7 6.146 5.854a.5.5 0 11.708-.708"/>',
    },
});
